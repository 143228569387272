import { useMemo, useState } from 'react'
import ProgressBar from 'components/MentorshipApplicationForm/ProgressBar'
import { colors, spacings } from 'stylesheets/theme'
import { Paragraph, SectionTitle } from 'components/Typography'
import Container from 'components/Container'
import { css } from '@emotion/react'
import Button, { Variant } from 'components/Button'
import ConfirmationModal from 'components/ConfirmationModal'
import Spinner from 'images/icons/spinner.svg'

interface AssessmentStepWrapperProps {
  isMobile?: boolean
  maxSteps: number
  currentStep: number
  children: JSX.Element
  onNext: (isSubmit?: boolean) => void
  onBackStep?: () => void
  isSubmitting?: boolean
}

const MOBILE_STEP_CONTAINER_HEIGHT = 112

const defaultProgressBarStyle = css({
  width: '100%',
})

const mobileProgressBarStyle = css({
  borderRadius: 0,
  position: 'fixed',
})

const defaultStepContainer = css({
  marginTop: 70,
})

const mobileStepContainer = css({
  width: '100%',
  padding: `${spacings.grid_gap_basis_num * 3}px ${
    spacings.grid_gap_basis_num * 2
  }px`,
  boxShadow: '0px 0px 4px 0px #00000033',
  backgroundColor: colors.backgrounds.white,
  height: MOBILE_STEP_CONTAINER_HEIGHT,
  position: 'fixed',
  bottom: 0,
})

const defaultConfirmationModalStyle = css({
  '&.mindr-modal.small': {
    width: spacings.grid_gap_basis_num * 58,
  },
})

const submittingConfirmationModalStyle = css({
  button: {
    display: 'none',
  },
})

const mobileConfirmationModalStyle = css({
  '&.mindr-modal.small': {
    width: 'auto',
    margin: spacings.grid_gap_basis_num * 2,
    '& .mindr-modal-actions': {
      '& .buttons-container': {
        width: '100%',
      },
      '& .button': {
        flex: '50%',
      },
    },
  },
})

const mobileContentBoxStyle = css({
  width: '100%',
  padding: `${spacings.grid_gap_basis_num * 3}px ${
    spacings.grid_gap_basis_num * 2
  }px`,
  overflow: 'hidden',
  marginBottom: MOBILE_STEP_CONTAINER_HEIGHT,
})

const desktopContentBoxStyle = css({
  width: 1137,
})

export default function AssessmentStepWrapper({
  isMobile = false,
  maxSteps,
  currentStep,
  children,
  onNext = () => {},
  onBackStep = () => {},
  isSubmitting = false,
}: AssessmentStepWrapperProps): JSX.Element {
  const [openSubmitConfirmation, setOpenSubmitConfirmation] = useState(false)
  const isLastPage = useMemo(
    () => currentStep === maxSteps,
    [currentStep, maxSteps],
  )

  const progressBarStyle = useMemo(
    () => [defaultProgressBarStyle, isMobile && mobileProgressBarStyle],
    [isMobile],
  )

  if (currentStep === 0) {
    return children
  }

  return (
    <>
      {isMobile && (
        <ProgressBar
          css={progressBarStyle}
          completed={(currentStep / maxSteps) * 100}
        />
      )}
      <div css={isMobile ? mobileContentBoxStyle : desktopContentBoxStyle}>
        <div
          css={{
            maxWidth: 1161,
            marginBottom: spacings.grid_gap_basis_num * 3,
            borderBottom: `1px solid ${colors.borders.gray}`,
          }}>
          <SectionTitle
            css={{
              color: colors.teal_dark,
              marginBottom: spacings.grid_gap_basis_num * 2,
            }}>
            LEADR™ assessment
          </SectionTitle>
          <Paragraph
            css={{
              marginBottom: spacings.grid_gap_basis_num * 2,
            }}>
            For each of the following situations, please&nbsp;
            <b>{isMobile ? 'tap in specific order' : 'drag and drop'}</b>
            &nbsp;to rank the four options from&nbsp;
            <b>1 to 4.</b>&nbsp;The answer you put&nbsp;<b>first</b>
            &nbsp;represents your&nbsp;<b>most</b>&nbsp;likely response to the
            situation, and&nbsp;
            <b>fourth</b>&nbsp;represents your least likely response.
          </Paragraph>
        </div>
        <form id="leadr_assessment_form">{children}</form>
        <ConfirmationModal
          css={[
            defaultConfirmationModalStyle,
            isMobile && mobileConfirmationModalStyle,
            isSubmitting && submittingConfirmationModalStyle,
          ]}
          isOpen={openSubmitConfirmation}
          onConfirm={() => onNext(true)}
          onCancel={() => setOpenSubmitConfirmation(false)}
          cancelButton="back"
          submitButton="confirm"
          title="Submit your LEADR™ assessment">
          {isSubmitting ? (
            <Spinner
              css={{
                width: 178,
                height: 161,
              }}
            />
          ) : (
            'Please review your responses and make any final adjustments before confirming. Are you sure you want to submit this assessment and view your report?'
          )}
        </ConfirmationModal>
      </div>
      <Container
        direction="column"
        css={isMobile ? mobileStepContainer : defaultStepContainer}>
        <Container
          css={
            isMobile
              ? css({
                  justifyContent: 'space-between',
                  width: '100%',
                })
              : null
          }>
          <Button
            variant={Variant.SECONDARY}
            onClick={onBackStep}
            type="button">
            back
          </Button>
          {!isMobile && (
            <ProgressBar
              css={{
                width: 857,
              }}
              completed={(currentStep / maxSteps) * 100}
            />
          )}
          <Button
            variant={Variant.PRIMARY}
            onClick={
              isLastPage
                ? () => setOpenSubmitConfirmation(true)
                : () => onNext(false)
            }
            type="button">
            {isLastPage ? 'submit' : 'next'}
          </Button>
        </Container>
        {!isMobile && (
          <Paragraph>
            {currentStep} of {maxSteps}
          </Paragraph>
        )}
      </Container>
    </>
  )
}
